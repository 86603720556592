<template>
  <ul class="list-unstyled">
    <b-media tag="li" class="border-bottom pb-4">
      <template #aside>
        <b-img src="img/dth_upcoming.png" width="40" alt="placeholder"></b-img>
      </template>
      <div class="d-flex justify-content-between">
        <div>
          <h6 class="mt-0 mb-1">Electricity Bill</h6>
          <p class="mb-0"><small>Consumer Number: 1234567890123</small></p>
          <p class="mb-0"><small>Due Date: 14/07/2022</small></p>
        </div>
        <div class="ml-2">
          <button type="button" class="btn btn-outline-primary paynow-btn">
            Pay Now
          </button>
        </div>
      </div>
    </b-media>
    <b-media tag="li" class="border-bottom pt-4 pb-4">
      <template #aside>
        <b-img src="img/dth_upcoming.png" width="40" alt="placeholder"></b-img>
      </template>
      <div class="d-flex justify-content-between">
        <div>
          <h6 class="mt-0 mb-1">Electricity Bill</h6>
          <p class="mb-0"><small>Consumer Number: 1234567890123</small></p>
          <p class="mb-0"><small>Due Date: 14/07/2022</small></p>
        </div>
        <div class="ml-2">
          <button type="button" class="btn btn-outline-primary paynow-btn">
            Pay Now
          </button>
        </div>
      </div>
    </b-media>
  </ul>
</template>
<script>
export default {};
</script>
<style scoped>
.btn-outline-primary.paynow-btn {
  color: #ff6a2e;
  border-color: #ff6a2e;
  padding: 2px 10px;
  font-size: 12px;
  line-height: 1.4;
}
.btn-outline-primary.paynow-btn:hover {
  color: #fff;
  background-color: #ff6a2e;
}
</style>
