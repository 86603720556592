var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "list-unstyled"
  }, [_c('b-media', {
    staticClass: "border-bottom pb-4",
    attrs: {
      "tag": "li"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-img', {
          attrs: {
            "src": "img/dth_upcoming.png",
            "width": "40",
            "alt": "placeholder"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h6', {
    staticClass: "mt-0 mb-1"
  }, [_vm._v("Electricity Bill")]), _c('p', {
    staticClass: "mb-0"
  }, [_c('small', [_vm._v("Consumer Number: 1234567890123")])]), _c('p', {
    staticClass: "mb-0"
  }, [_c('small', [_vm._v("Due Date: 14/07/2022")])])]), _c('div', {
    staticClass: "ml-2"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary paynow-btn",
    attrs: {
      "type": "button"
    }
  }, [_vm._v(" Pay Now ")])])])]), _c('b-media', {
    staticClass: "border-bottom pt-4 pb-4",
    attrs: {
      "tag": "li"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-img', {
          attrs: {
            "src": "img/dth_upcoming.png",
            "width": "40",
            "alt": "placeholder"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h6', {
    staticClass: "mt-0 mb-1"
  }, [_vm._v("Electricity Bill")]), _c('p', {
    staticClass: "mb-0"
  }, [_c('small', [_vm._v("Consumer Number: 1234567890123")])]), _c('p', {
    staticClass: "mb-0"
  }, [_c('small', [_vm._v("Due Date: 14/07/2022")])])]), _c('div', {
    staticClass: "ml-2"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary paynow-btn",
    attrs: {
      "type": "button"
    }
  }, [_vm._v(" Pay Now ")])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }